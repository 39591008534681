//header section

.navbar {
  padding: 5px 15px;
  .navbar-toggler {
    border: none;
    &:focus {
      &:not(:focus-visible) {
        outline: none !important;
        border: none;
        box-shadow: none;
      }
    }
  }

  .navbar-brand {
    img {
      height: 60px;
    }
  }
  .navbar-collapse {
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 70px;
        cursor: pointer;
      }
    }
    .btn-section {
      button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .common-btn,
    .odd-btn {
      width: 120px; // Set a fixed width for buttons
      text-align: center;
      padding: 7px 0;
    }

    .common-btn {
    }
    .odd-btn {
      border: 1px solid $primary_color;
    }
  }
}

//Banner section
.banner-section {
  padding: 5% 0% 10% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  h5 {
    font-weight: 600;
  }
  h1 {
    span {
      color: $secondary_color;
    }
  }
  .button-div {
    .common-btn,
    .odd-btn {
      padding: 12px 20px;
      width: 46%;
    }
    .common-btn {
      margin-right: 20px;
    }
  }
  .ellipse {
    height: 110%;
  }
  .banner-img {
    top: 13%;
    height: 80%;
  }
}
//Offer Section
.offer-section {
  padding: 4% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: unset;
    margin-top: 10px;
    .feature-item {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 15px 0px;
      border-radius: 8px;
      // .img-icon {

      //   img {
      //     height: 21px;
      //   }
      // }
    }
  }
  .line-img {
    right: 20%;
    top: 0;
  }
  .circle-img {
    right: 0;
    bottom: 0;
  }
  .upper-img {
    top: 0;
    left: 0;
    transform: scaleY(-1);
  }
  .bigline-img {
    left: 19%;
    top: 0;
  }
}

//Why section
.why-section {
  padding: 4% 0%;
  background: linear-gradient(180deg, #ffffff 0%, #f7fcf4 60%);

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: unset;
    margin-top: 9px;
    .feature-item {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 15px 0px;
      border-radius: 8px;
      line-height: 30px;
      // }
    }
  }
  button {
    padding: 10px 50px;
    margin-top: 20px;
  }
}

//Module section
.module-section {
  padding: 4% 0%;
  .accordion {
    .accordion-item {
      border: none !important; /* Removes the border */
      box-shadow: none !important; /* Removes shadow effects */
      background-color: transparent;
    }
    // .accordion-button {
    //   padding: 15px 12px;
    //   border-radius: unset !important;
    //   box-shadow: none !important;
    //   background-color: transparent;
    //   &.collapsed {
    //     border-bottom: 1px solid #d9d9d9;
    //   }
    //   &:not(.collapsed) {
    //     background-color: $ternay_color;
    //   }
    //   .icon {
    //     padding-right: 10px;
    //   }
    //   &::after {
    //     content: none !important;
    //   }
    //   .accordion-header-content {
    //     display: flex;
    //     flex-direction: column;
    //   }
    //   .accordion-icon {
    //     content: "";
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 20px;
    //     height: 20px;
    //     border-radius: 50%;
    //     font-size: 15px;
    //     font-weight: bold;
    //     color: #bebebe;
    //     transform: rotate(0deg);
    //     background: none;
    //     border: 1px solid #bebebe;
    //     box-shadow: none;
    //   }

    //   &.collapsed .accordion-icon::after {
    //     content: "+";
    //   }
    //   &:not(.collapsed) .accordion-icon::after {
    //     content: "-";
    //     color: $secondary_color;
    //   }

    //   p {
    //     font-size: 16px;
    //     font-weight: 300;
    //     line-height: 26px;
    //   }
    // }
    .accordion-button {
      padding: 15px 12px;
      border-radius: unset !important;
      box-shadow: none !important;
      background-color: transparent;
      // Ensure no border initially
      // border-bottom: 1px solid #d9d9d9 !important; // Add border-bottom when collapsed
      &.collapsed {
        border-bottom: 1px solid $secondary_color !important; // Add border-bottom when collapsed
        .accordion-icon::after {
          content: "+";
          color: $secondary_color;
        }
      }

      &:not(.collapsed) {
        // border-bottom: none !important; // Remove border-bottom when expanded
        background-color: $ternay_color;
        .accordion-icon::after {
          content: "-";
          color: $secondary_color;
        }
        h5 {
          color: #000;
        }
      }

      .icon {
        padding-right: 15px;
      }

      &::after {
        content: none !important;
      }

      .accordion-header-content {
        display: flex;
        flex-direction: column;
      }

      .accordion-icon {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 15%;
        font-size: 15px;
        font-weight: bold;
        color: #bebebe;
        transform: rotate(0deg);
        background: none;
        border: 1px solid $secondary_color;
        box-shadow: none;
      }
    }

    .accordion-body {
      background-color: $ternay_color;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      padding-top: 0;
      ul {
        padding-left: 4rem;
      }
    }
  }
  .common-btn {
    padding: 10px 50px;
  }
}
//Client
.client-section {
  padding: 4% 0%;

  .client-banner {
    height: 70%;
    bottom: 15%;
    right: 12px;
  }
  .common-btn {
    padding: 10px 50px;
  }
  .client-logos {
    background-color: #fff;
    padding: 15px;
    border-radius: 9px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.0784313725);
    h5 {
      margin-bottom: 26px;
    }
  }
}
//Pricing{
.pricing-section {
  padding: 4% 0%;
  min-height: 500px;
  .module-list {
    box-shadow: 0px 2px 8px 0px #00000014;
    padding: 11px 15px;
    border-radius: 10px;
    background-color: #fff;

    cursor: pointer;
    &.selected {
      background-color: $secondary_color;
      color: #fff;
      svg {
        fill: #fff; // White icon for minus
      }
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }

    .calculation-div {
      padding: 6px 15px;
      .price-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 60px;
      }
      h4 {
        font-size: 36px;
        font-weight: 600;
        line-height: 60px;
      }

      .discount-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 60px;
      }
    }
  }
  .total-saving {
    border: 1px dashed $secondary_color;
    border-radius: 10px;
    padding: 6px 6px;
  }
  .monthly-btn,
  .yearly-btn {
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
    padding: 0px 35px;
    border-radius: 50px;
    background-color: #fff;
    color: $secondary_color;
    border: 1px solid $secondary_color;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active {
      background-color: $secondary_color;
      color: white;
      border-color: $secondary_color;
    }
  }
  .monthly-btn {
    margin-right: 20px;
  }
}
//Faqs
.faqs-section {
  padding: 4% 0%;

  .accordion {
    .accordion-item {
      margin: 10px;
      border: none !important; /* Removes the border */
      box-shadow: none !important; /* Removes shadow effects */
      .accordion-header {
        line-height: 20px;
        .accordion-button {
          &:not(.collapsed) {
            box-shadow: none;
            background: #fff;
            color: #000;
          }
          &.collapsed {
            box-shadow: none;
          }
        }
        &:focus {
          box-shadow: unset;
        }
      }
      .accordion-body {
        text-align: start;
        padding-top: 0px;
        font-size: 13px;
      }
    }
  }
}
//Ready Section
.ready-section {
  padding: 4% 0%;
  background: linear-gradient(180deg, #ffffff 0%, #f7fcf4 60%);
  h6 {
    span {
      font-weight: 600;
    }
  }
  button {
    padding: 10px 50px;
    margin-top: 4%;
  }
  img {
    top: 0;
    right: 0;
    transform: scaleY(-1);
  }
}

//Contact
.contact-form-modal {
  .modal-dialog {
    width: 60%;
  }
  .close-button {
    cursor: pointer;
  }
  .heading-contact {
    display: flex;
    justify-content: start;
    padding-bottom: 10px;
  }
  .line-side {
    background: $secondary_color;
    height: 2px;
    margin-top: 10px !important;
    width: 82px;
  }
  .line-content {
    font-size: 14px;
    padding-left: 10px;
    color: $secondary_color;
  }
  .hl-text {
    color: $secondary_color;
  }
  p {
    padding: 0px 0 25px 0;
  }

  .btn-close {
    font-size: 16px;
    margin-right: 15px;
    &:focus {
      outline: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }
  .modal-body {
    padding: 20px 30px;

    input {
      padding: 4% 7%;
      &::-webkit-input-placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
      }
      &:focus {
        outline: none !important;
        border: 1px solid #d9d9d9;
        box-shadow: none;
      }
    }
    button {
      font-size: 18px;
      font-weight: 400;
      line-height: 21.78px;
      padding: 11px 50px;
    }
  }
}
.static-page {
  padding: 30px 0px;
  p {
    line-height: 20px !important;
  }
}

//Footer
.footer {
  img {
    height: 60px;
    margin-bottom: 20px;
  }
  .footer-row {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }
  .footer-logo-column {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    .contact-info {
      p {
        a {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
  .footer-second-column {
    // ul {
    //   margin: 0;
    //   display: flex;
    //   padding-left: 10px;
    //   li {
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 30px;
    //     cursor: pointer;
    //     &:not(:last-child) {
    //       margin-right: 50px;
    //     }
    //   }
    // }
  }
  .ourshop_com {
    color: rgba(0, 0, 0, 0.45);
    padding: 5px !important;
  }
  .copyright {
    .middle_paragraph {
      font-size: 14px;
      display: flex;
      justify-content: center;
      line-height: 19px;
      gap: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  .footer-color-content {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }
}

@media only screen and (max-width: 991px) {
  //Header

  .navbar {
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 10;
    .navbar-brand {
      img {
        height: 50px;
      }
    }

    .navbar-collapse {
      ul {
        align-items: flex-start;
        li {
          padding: 10px 0px;
          font-size: 14px;
          a {
            font-size: 14px;
          }
        }
      }
      .common-btn,
      .odd-btn {
        width: 120px;
        padding: 10px 5px !important;
      }
    }
  }

  //Banner section
  .banner-section {
    padding: 30% 7% 10% 7%;
    .button-div {
      .common-btn,
      .odd-btn {
        width: 85%;
        padding: 13px 20px;
      }
      .common-btn {
        margin: 0px 0px 20px 0px;
      }
    }
  }
  .banner-img-mob {
    left: 0;
    width: 100%;
    bottom: 10px;
  }
  //Offer
  .offer-section {
    padding: 10% 10px;
    ul {
      margin: 20px 0px;
      .feature-item {
        padding: 0;
        .icon-container {
          border-radius: 5px;
        }
      }
    }
  }
  //Why section
  .why-section {
    padding: 10% 10px;
    ul {
      margin: 20px 0px;
      .feature-item {
        padding: 0;
        .icon-container {
          border-radius: 5px;
          img {
            height: 21px;
          }
        }
      }
    }
    button {
      border-radius: 5px;
    }
  }
  //Module section
  .module-section {
    padding: 10% 10px;
    .accordion {
      .accordion-body {
        font-size: 14px;
        font-weight: 300;
        line-height: 15px;
        ul {
          padding-left: 3rem;
        }
      }
    }
  }
  //Client
  .client-section {
    .client-text {
      padding: 10% 10px;
    }
  }
  //Pricing{
  .pricing-section {
    padding: 10% 10px;
    .monthly-btn,
    .yearly-btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  //Faq
  .faqs-section {
    .accordion-button {
      font-size: 16px;
    }
    .accordion-body {
      font-size: 14px !important;
    }
  }
  //Ready
  .ready-section {
    padding: 10% 10px;
    h2 {
      padding: 0px 35px;
    }
    h6 {
      padding: 0px 36px;
    }
    button {
      border-radius: 5px;
      padding: 13px 50px;
      margin-top: 8%;
    }
  }
  .contact-form-modal {
    .modal-dialog {
      width: 100%;
    }
  }

  //Footer
  .footer {
    img {
      height: 50px !important;
      margin-bottom: 30px;
    }
    // .footer-row {
    //   padding: 7% 10px;
    // }
    .pb-30 {
      padding-bottom: 5px;
    }
    .quick-section {
      padding: 10px 0px;
      .quick-links {
        ul {
          li {
            font-size: 14px;
            font-weight: 400;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.45);
            &:not(:last-child) {
              margin-right: 30px !important;
            }
          }
        }
      }
    }

    .footer-second-column {
    }
    .ourshop_com {
      font-size: 12px;
      padding: 15px !important;
    }
    .copyright {
      padding: 8px 0px;
      span {
        font-size: 10px;
        font-weight: 300;
        line-height: 22.5px;
      }
    }

    .footer-color-content {
      color: rgba(0, 0, 0, 0.45) !important;
      font-weight: 500 !important;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      font-size: 14px;
      text-decoration: none;
    }
  }
}
.footer-header {
  line-height: 21px;
}
.conditionslinks {
  @media only screen and (max-width: 991px) {
    margin-top: 60px !important;
  }
}
.discount-price {
  padding-left: 5px;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
}
.discount-text {
  margin-top: 2px;
}
.pricingbtn {
  padding: 10px 50px;
}

//Details page
.heading-wrapper {
  margin-bottom: 30px;
}
.text-wrapper {
  margin-bottom: 30px;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.features-block {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.call-to-action-btn {
  text-align: right;
  color: $primary_color;
}
// .list-style-one {
//   margin-top: 30px;
//   padding: 0;
//   li {
//     flex: 0 0 50%;
//     width: 100%;
//     margin-bottom: 12px;
//     position: relative;
//     padding-left: 30px;
//     &::before {
//       background: $secondary_color;
//       content: "";
//       width: 3px;
//       height: 10px;
//       position: absolute;
//       top: 9px;
//       left: 0;
//     }
//     &::after {
//       left: 5px;
//       width: 10px;
//       background: $secondary_color;
//       content: "";
//       height: 10px;
//       position: absolute;
//       top: 9px;
//     }
//   }
// }
.page-header {
  display: flex;
  height: 100%;
  align-items: center;
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.full-height {
  min-height: 100vh;
}
.common-icon-box {
  text-align: center;
  padding: 30px 30px 30px 30px;
  .icon {
    background: linear-gradient(
      -135deg,
      rgba($primary_color, 0.6) 0%,
      rgba($secondary_color, 0.8) 100%
    );
    background: -webkit-linear-gradient(
      -135deg,
      rgba($primary_color, 0.6) 0%,
      rgba($secondary_color, 0.8) 100%
    );
    height: 120px;
    width: 120px;
    padding: 10px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    margin: 0px auto 30px;
    svg {
      color: #fff;
    }
  }
}
.cms-component {
  .banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  // .common-btn {
  //   padding: 12px 20px;
  // }
}
.features-section {
  .inner-row {
    .col-lg-6:nth-child(2) {
      margin-top: 80px;
    }
    .col-lg-6:nth-child(3) {
      margin-top: -100px;
    }
  }
  .features-block {
    .inner-box {
      > * {
        position: relative;
      }
      background: #fff;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out;
      padding: 40px 30px;
      position: relative;
      text-align: center;
      overflow: hidden;
      cursor: pointer;

      &::before {
        -webkit-transform: translateY(102%);
        transform: translateY(102%);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        height: 100%;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
      }
    }
    .inner-box:hover {
      &::before {
        // background: linear-gradient(-135deg, rgba(169, 235, 39, 0.5411764706) 0%, #7cbb00 100%);
        background: linear-gradient(
          -135deg,
          rgba($primary_color, 0.6) 0%,
          rgba($secondary_color, 0.8) 100%
        );
        // background: linear-gradient(-135deg, $primary_color 0%, $secondary_color 100%);

        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      .icon {
        svg {
          color: #fff;
        }
      }
      .text {
        h5,
        p {
          color: #fff;
        }
      }
    }
    .icon {
      margin: 0px auto 18px;
      text-align: center;
      display: table;
      svg {
        color: $secondary_color;
      }
    }
    h4 {
      font-size: 24px;
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out;
      letter-spacing: -0.4px;
      margin-bottom: 18px;
    }
  }
}
.choose-cms {
  background: linear-gradient(180deg, #fff, #f7fcf4 60%);
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 0;
    li {
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;
      margin-bottom: 12px;
      position: relative;
      padding-left: 30px;
      &::after {
        left: 5px;
        width: 10px;
      }
    }
  }

  .list-style-one li:before {
    background: $secondary_color;
    content: "";
    width: 3px;
    height: 10px;
    position: absolute;
    top: 9px;
    left: 0;
  }
  .list-style-one li:after {
    background: $secondary_color;
    content: "";
    height: 10px;
    position: absolute;
    top: 9px;

    left: 5px;
    width: 10px;
  }
}
.cms-feature {
  .features-block {
    .inner-box {
      background: #fff;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid #ebebeb;
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out;
      padding: 30px 15px;
      position: relative;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      min-height: 213px;
      &:hover {
        -webkit-box-shadow: 0px 0px 30px 0px #7cbb0033;
        box-shadow: 0px 0px 30px 0px #7cbb0033;
        border: 1px solid $secondary_color;
      }
      .icon {
        margin: 0px auto 18px;
        text-align: center;
        display: table;
        svg {
          max-width: 68px;
          max-height: 68px;
          color: $secondary_color;
          -webkit-transition: all 400ms ease-in-out;
          transition: all 400ms ease-in-out;
        }
      }
    }
  }
}

.ss-wrapper {
  max-width: 1170px;
  position: relative;
  margin: 0px auto 0px;
  .laptop-img-bg {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .screenshot-slider {
    position: relative;
    padding: 5.15% 17.5% 7% 17.7%;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    display: block;
  }
  .slick-slider {
    direction: ltr !important;
  }
}

.benefits-section {
  // background-color: #d8f3c8;
  background-color: $ternay_color;
  .features-block {
    .inner-box {
      transition: all 400ms ease-in-out;
      padding: 40px 30px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      cursor: pointer;
      // background: rgba(255, 255, 255, 0.1);
      background: rgba(232, 250, 223, 0.69);
      -webkit-transition: all 400ms ease-in-out;
      &:hover {
        // background: #7cbb0070;
        // background: linear-gradient(-135deg, $primary_color 0%, $secondary_color 100%);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.timeline-item:nth-child(even) {
  .timeline-wrapper {
    padding-top: 0px;
    padding-bottom: 100px;
  }
}

.timeline-slider {
  &::before {
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, #00aaff),
      color-stop(100%, #17bb00)
    );
    background: -webkit-linear-gradient(left, #00aaff 0%, #17bb00 98%);
    background: linear-gradient(to right, #00aaff 0%, #17bb00 98%);
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0px;
    height: 12px;
    margin-top: -6px;
  }
  .timeline-item {
    text-align: center;
    padding: 0px 15px;
    position: relative;
    &:nth-child(even) {
      .timeline-wrapper {
        padding-top: 0px;
        padding-bottom: 100px;
      }
    }
    &::before {
      background: $secondary_color;
      height: 32px;
      width: 32px;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50px;
      border: 4px solid #fff;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .content {
      padding: 80px 0 0 0;
      p {
        margin-bottom: 0;
      }
    }
  }
  .timeline-wrapper {
    padding-top: 100px;
    position: relative;
  }
  .step-number {
    color: $secondary_color;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 30px;
    font-family: "Alegreya", serif;
    font-style: italic;
    font-size: 98px;
    font-weight: bold;
    line-height: 0.8;
    height: 98px;
    span {
      display: block;
    }
  }
}

//hris
.hris-component {
  .section-2 {
    .inner-box {
      background: #fff;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out;
      padding: 40px 30px;
      position: relative;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      z-index: 1;
      &::before {
        -webkit-transform: translateY(102%);
        transform: translateY(102%);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        height: 100%;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
        z-index: 0;
      }
      .icon {
        position: relative;
        z-index: 2;
        svg {
          color: $secondary_color;
          transition: color 0.3s;
        }
      }
    }
    .text {
      position: relative;
      z-index: 2;
      h4 {
        margin-bottom: 18px;
        letter-spacing: -0.4px;
        transition: color 0.3s;
      }
    }
    .inner-box:hover {
      h4,
      p {
        color: #fff;
      }
      color: #fff;
      &::before {
        background: linear-gradient(
          -135deg,
          rgba($primary_color, 0.6) 0%,
          rgba($secondary_color, 0.8) 100%
        );
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      .icon svg {
        color: #fff;
      }
    }
  }

  .section-3 {
    .icon-box.theme-one {
      text-align: center;
      padding: 30px 30px 30px 30px;
      .icon {
        background: linear-gradient(
          -135deg,
          rgba($primary_color, 0.6) 0%,
          rgba($secondary_color, 0.8) 100%
        );
        height: 120px;
        width: 120px;
        padding: 10px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        line-height: 100px;
        text-align: center;
        margin: 0px auto 30px;
        svg {
          color: #fff;
        }
      }
      h4 {
        margin-bottom: 18px;
        letter-spacing: -0.4px;
      }
    }
  }
}
//admin panel
.admin-component {
  .page-header {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .right-col-full .col-lg-6:first-child {
    padding-left: #{calc(((100vw - (100vw - 100%)) - 1170px) / 2)};
  }
  .right-col-full .col-lg-6:last-child {
    padding-right: 0;
  }
  .right-col-full .container {
    width: 100%;
    max-width: 100%;
  }
  .text-wrapper {
    margin-bottom: 30px;
  }
  .full-width-img img {
    width: 100%;
  }
  .screenshot-slider {
    .slick-arrow {
      background: linear-gradient(
        -135deg,
        $primary_color 0%,
        $secondary_color 100%
      );
      box-shadow: 0px 0px 20px 0px rgba(42, 31, 188, 0.4);
      height: 50px;
      width: 50px;
      line-height: 50px;
      color: #fff;
      border-radius: 50%;
    }
    .slick-prev {
      left: 60px;
      &::before {
        content: "\2039";
        margin-right: 5px;
      }
    }
    .slick-next {
      right: 60px;
      &::before {
        content: "\203A";
        margin-left: 5px;
      }
    }
    .slick-prev,
    .slick-next {
      &::before {
        font-weight: 900;
        color: #fff;
        font-size: 45px;
        line-height: 36px;
        opacity: 1;
      }
      font-size: 0;
      position: absolute;
      top: 50%;
      display: block;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      border: none;
    }
  }
}
//asset
.page-wrapperbg-light {
  .dc-ten {
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
  }

  .dc-features-group {
    position: relative;
    overflow: hidden;
    .dc-features-item {
      margin-bottom: -1px;
      border-right: 1px solid #f0f0f0;
      // &:hover{
      //   .dc-features-item-front{
      //     -webkit-transform: translateY(-100%);
      //     -ms-transform: translateY(-100%);
      //     transform: translateY(-100%);
      //   }
      //   .dc-features-item-hover{
      //     top: 0%;
      //     opacity: 1;
      //     visibility: visible;
      //   }
      // }
      &:nth-child(n) {
        border-bottom: 1px solid #f0f0f0;
      }
      // .dc-features-item-front,
      // .dc-features-item-hover {
      //   min-height: 333px;
      //   height: auto;
      //   padding: 20px 45px;
      //   -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      //   transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      //   display: -webkit-box;
      //   display: flex;
      //   -webkit-box-align: center;
      //   align-items: center;
      // }
      // .dc-features-item-front {
      //   position: relative;
      //   .inner-box {
      //     width: 100%;
      //     text-align: center;
      //   }
      //   &:hover {
      //     .inner-box {
      //       width: 100%;
      //       text-align: center;
      //     }
      //   }
      // }
      // .dc-features-item-hover {
      //   position: absolute;
      //   top: 100%;
      //   left: 0;
      //   height: 100%;
      //   width: 100%;
      //   background-repeat: no-repeat;
      //   background-position: bottom center;
      //   background-size: contain;
      //   opacity: 0;
      //   visibility: hidden;
      //   &::before {
      //     background-color: #291fbc;
      //     content: "";
      //     position: absolute;
      //     bottom: 0;
      //     left: 0;
      //     height: 100%;
      //     width: 100%;
      //     mix-blend-mode: multiply;
      //   }
      //   .inner-box{
      //     position: relative;
      //     z-index: 2;
      //     width: 100%;
      //     text-align: center;
      //     .dc-features-title{
      //       color: #fff;
      //       margin-bottom: 30px;
      //     }
      //     p{
      //       color: #fff;
      //       margin-bottom: 0;
      //     }
      //   }
      // }
      .icon {
        margin-bottom: 30px;
        svg {
          -webkit-transition: all 400ms ease-in-out;
          transition: all 400ms ease-in-out;
        }
      }
    }
  }
  .dc-features-group > .dc-features-item {
    margin-bottom: -1px;
    border-right: 1px solid #f0f0f0;
    padding: 0;
  }
  .dc-features-group > .dc-features-item > .dc-features-item-div {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .dc-features-item-front .inner-box,
  .dc-features-item-hover .inner-box {
    width: 100%;
    text-align: center;
  }
  .dc-features-group > .dc-features-item .dc-features-item-front {
    min-height: 333px;
    height: auto;
    padding: 20px 45px;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    .icon {
      svg {
        color: $secondary_color;
      }
    }
  }
  .dc-features-group .dc-features-item-div:hover .dc-features-item-front {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .dc-features-group .dc-features-item-div:hover .dc-features-item-hover {
    top: 0%;
    opacity: 1;
    visibility: visible;
  }
  .dc-features-group > .dc-features-item .dc-features-item-hover {
    position: absolute;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    opacity: 0;
    visibility: hidden;
    min-height: 333px;
    padding: 20px 45px;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
  .dc-features-group > .dc-features-item .dc-features-item-hover::before {
    // background: linear-gradient(-135deg, rgba(169, 235, 39, 0.5411764706) 0%, #7cbb00 100%);
    background: linear-gradient(
      -135deg,
      $primary_color 0%,
      $secondary_color 100%
    );
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
  }
  .dc-features-item-hover .inner-box {
    position: relative;
    z-index: 2;
  }
  .dc-features-item-hover .dc-features-title,
  .dc-features-item-hover p {
    color: #ffffff;
    margin-bottom: 30px;
  }
  .dc-features-item-div {
    color: #000;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}
//ecommerse
.ecommerse-component {
  .page-header {
    padding-bottom: 100px;
  }
  .core-service {
    .features-block {
      position: relative;
      z-index: 1;
      margin-bottom: 30px;
      .inner-box {
        background: #fff;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        -webkit-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;
        padding: 30px 15px;
        position: relative;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        min-height: 225px;
        &:hover {
          -webkit-box-shadow: 0px 0px 30px 0px rgba($secondary_color, 0.4);
          box-shadow: 0px 0px 30px 0px rgba($secondary_color, 0.4);
          border: 1px solid rgba($secondary_color, 0.8);
        }
        .icon {
          margin: 0px auto 18px;
          text-align: center;
          display: table;
          svg {
            color: $secondary_color;
          }
        }
      }
    }
  }

  .st-tab-btn {
    padding: 0;
    margin-bottom: 15px;

    .nav-tabs {
      border-bottom: 3px solid #ebebeb;

      .nav-link {
        position: relative;
        font-weight: 700;
        font-size: 18px;
        padding: 15px;
        background: none;
        border: 0;
        color: rgba($primary_color, 0.6);
        &:hover{
          color: rgba($primary_color, 0.6);
        }
        &::after {
          content: "";
          display: block;
          height: 3px;
          width: 0;
          position: absolute;
          bottom: 0;
          left: 50%;
          transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
          background: linear-gradient(
            to right,
            rgba($primary_color, 0.4) 0%,
            rgba($secondary_color, 0.8) 100%
          );
        }

        &.active {
          color: $secondary_color;

          &::after {
            left: 0;
            width: 100%;
          }
        }
      }

      .nav-item {
        border: 0;
        margin-right: 15px;
        padding: 0;

        .nav-link {
          border: 0;
          position: relative;
          &::after {
            content: "";
            display: block;
            height: 3px;
            width: 0;
            position: absolute;
            bottom: 0;
            left: 50%;
            transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
            background: linear-gradient(
              to right,
              rgba($primary_color, 0.4) 0%,
              rgba($secondary_color, 0.8) 100%
            );
          }

          &.active::after {
            left: 0;
            width: 100%;
          }
        }
      }

      .nav-item.show .nav-link::after {
        left: 0;
        width: 100%;
      }
    }
  }
  .st-tab-content {
    .tab-pane {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}
//crm
.crm-component {
  .page-header {
    background-position: center top;
    .heading-wrapper {
      margin-top: 50px;
      h1 {
        margin-bottom: 18px;
      }
      .lead-text {
        h6 {
          color: #fff;
        }
      }
    }
  }
  .screenshot-slider {
    .slick-arrow {
      display: none !important;
    }
  }
}
//Account
.account-component {
  .features-block {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    .inner-box {
      background: #fff;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 400ms ease-in-out;
      transition: all 400ms ease-in-out;
      padding: 40px 50px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      .icon {
        margin: 0px 30px 0px 0px;
        text-align: center;
        display: table;
        svg {
          color: $secondary_color;
        }
      }
    }
  }
  #accordion {
    .panel {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      border-radius: 6px;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      background: var(--white-color);
      border: none;
      padding: 20px 20px;
      .panel-title {
        font-size: 18px;

        a {
          color: #000;
          position: relative;
          display: block;
          // justify-content: space-between;
          padding: 0 30px 0 0;
          &::after {
            // content: "\02C4";
            position: absolute;
            font-family: "Font Awesome 5 Free";
            content: "\f077";
            background: #291fbc;
            height: 32px;
            width: 32px;
            border-radius: 50px;
            line-height: 32px;
            text-align: center;
            font-size: 18px;
            top: -5px;
            color: #fff;
          }
          .collapsed {
            &::after {
              content: "\2304";
            }
          }
        }
        a.collapsed {
          color: #000;
        }
      }
    }
  }
}
